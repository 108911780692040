.App {
  text-align: center;
}
body {
  background-color: #000;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

iframe{
  display:none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 550px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.button-lime{
  background-color: #d3bd0e;
}

.p-20{
  padding: 0 20px;
}

.button-lime-hover:hover{
  background-color: #ebd10f;
}

.color-lime{
color: #d3bd0e;
}

w3m-core-button w3m-connect-button button{
  background-color: #d3bd0e !important;
}
.btn:disabled{
  background-color: #d3bd0ec2;
  border-color: #d3bd0e;
}

.bg-black{
  background-color: #000;
}

.copyIcon{
  position: absolute;
  top: 7px;
  right: 14px;
  cursor: pointer;
  z-index: 11;
  background: #fff;
  padding: 0 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.metaModal .modal-content{
  background: rgb(0, 25 ,38) !important;
}
.metaModal .modal-header{
  border-bottom: 0;
}
.metaModal .modal-header .h4{
  Color: #fff !important;
  font-size: 24px;
  margin-bottom: 0;
}

.metaModal .modal-body .h4{
  color: #fff;
  font-size: 16px;
}

.metaModal .modal-body .walletLogo{
  width: 100px !important;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 24px;
  cursor: pointer;
}

.metaModal .modal-body .walletLogo img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.metaModal {    
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.disabled {
  opacity: .5;
  cursor: default;
  pointer-events: none;
}

.img-table{
  width:100px;
  height:100px;
}

.img-table img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal-img{
  width:300px;
  height:200px;
  margin:0 auto;
}

.modal-img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width:767px){
  .navbar-collapse.show{
    display: block !important;
  }
}

.filter-box{
  height: 32px;
	width: 200px;
	border-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #000;
	padding: 0 32px 0 16px;
}

.filter-button{
  border: none;
	height: 30px;
	width: 30px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
  background-color: #d3bd0e;
  color:#fff;
}